<template>
    <div class="opportunity-resume-detail" v-loading="loading">
        <div class="opportunity-resume-detail-header">
            <div class="left">
                <div class="avatar">
                    <img v-if="resumeInfo.avatarURL" :src="resumeInfo.avatarURL" />
                    <img v-else src="~@src/assets/images/default-user.png" />
                </div>

                <div class="base-info">
                    <p class="headline-base">
                        <span class="headline-name" :title="resumeInfo.realName">{{ resumeInfo.realName || '未知'}}</span>

                        <span>{{ resumeInfo.gender | genderFilter}}</span>
                        <i class="headline-border">|</i>

                        <!-- <span>{{ resumeInfo.yearOfExperience ? resumeInfo.yearOfExperience + '年' : '未知' }}</span>
                        <i class="headline-border">|</i> -->

                        <span>{{ birthdayToAge(resumeInfo.birthday) }}</span>
                    </p>

                    <div class="headline-info">
                        <div class="item">
                            <p class="school">
                                <i class="headline-title">学校：</i>{{ resumeInfo.school || '未知' }}
                            </p>
                            <p class="degree">
                                <i class="headline-title">学历：</i>{{ resumeInfo.degree | degreeFilter }}
                            </p>
                            <p class="company">
                                <i class="headline-title">公司：</i>{{ resumeInfo.company || '未知' }}
                            </p>
                        </div>
                        <div class="item">
                            <p class="mobile">
                                <i class="headline-title">手机：</i>{{ resumeInfo.mobile || '-' }}
                            </p>
                            <p class="email">
                                <i class="headline-title">邮箱：</i>
                                <span>{{ resumeInfo.email || '-' }}</span>
                            </p>
                            <p class="wechat">
                                <i class="headline-title">微信：</i>{{ resumeInfo.wechat || '-' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <opportunity-resume-detail-content
            ref="publicResumeContent"
            :resumeInfo="resumeInfo"
        ></opportunity-resume-detail-content>

        <div class="lbd-sign"></div>
    </div>
</template>

<script>
import OpportunityResumeDetailContent from "./layout/resume-detail-content.vue";
import PublicJobService from '#/js/service/publicJobService.js';
import { getAgeFromBirthday } from '#/js/util/tools.js';
import DegreeKeyValue from '#/js/config/degreeKeyValue.json';

export default {
    components: {
        OpportunityResumeDetailContent,
    },
    data() {
        return {
            loading: false,
            resumeInfo: {},
        };
    },

    filters: {
        genderFilter(val) {
            const genderMap = ['未知','男','女'];
            return genderMap[val] || '未知';
        },
        degreeFilter(val) {
            return DegreeKeyValue[val] || '未知';
        },
    },

    mounted() {
        this.getResumeDeatil();
    },

    methods: {
        birthdayToAge(birthday) {
            return getAgeFromBirthday(birthday) ? getAgeFromBirthday(birthday) + '岁' : '未知';
        },
        getResumeDeatil() {
            this.loading = true;
            PublicJobService.getDeliverResumeDetail(this.$route.params.id)
                .then(res => {
                    this.loading = false;
                    console.log(res);
                    this.resumeInfo = res;
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
        }
    },
};
</script>

<style lang="scss" scoped>
.opportunity-resume-detail {
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    &-header {
        width: 1200px;
        height: 180px;
        margin: 0 auto 20px;
        padding: 40px;
        background-color: #fff;
        border-radius: 8px;
        .left {
            display: flex;
            .avatar {
                margin-right: 30px;
                width: 70px;
                height: 70px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .base-info {
                .headline-base {
                    margin-bottom: 20px;
                    color: #444444;
                    font-size: 14px;
                    position: relative;
                    .headline-name {
                        font-weight: bold;
                        font-size: 20px;
                        margin-right: 10px;
                        max-width: 140px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .headline-border {
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }
                .headline-info {
                    .item {
                        height: 30px;
                        p {
                            margin: 0 10px 10px 0;
                            display: inline-block;
                            width: 230px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .email {
                            white-space: normal;
                            text-overflow: initial;
                            vertical-align: top;
                            &.span {
                                display: inline-block;
                                max-width: 186px;
                                white-space: normal;
                                word-wrap: break-word;
                                word-break: break-all;
                                vertical-align: top;
                            }
                        }
                    }
                }
            }
        }
    }

    .lbd-sign {
        padding: 20px 20px 0;
        &:after {
            content: '© 2024 liebide.com';
            margin-left: 50%;
            text-align: center;
            font-size: 14px;
            color: #999;
        }
    }
}
</style>