var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "opportunity-resume-detail-content" }, [
    _c("div", { staticClass: "standard-resume" }, [
      _vm.resumeInfo.intentions?.length ||
      _vm.resumeInfo.workExperiences?.length ||
      _vm.resumeInfo.projectExperiences?.length ||
      _vm.resumeInfo.educationExperiences?.length ||
      _vm.resumeInfo.selfEvaluation
        ? _c("div", { staticClass: "resume-wrapper" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.resumeInfo.intentions?.length,
                    expression: "resumeInfo.intentions?.length",
                  },
                ],
                staticClass: "resume-module expect-intentions",
              },
              [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "module-main" },
                  _vm._l(_vm.resumeInfo.intentions, function (item, index) {
                    return _c(
                      "div",
                      { key: "intentions" + index, staticClass: "module-item" },
                      [
                        _c("div", { staticClass: "item-header" }, [
                          _c("p", [
                            _c("span", {
                              staticClass: "header-text",
                              domProps: { innerHTML: _vm._s(item.title) },
                            }),
                            _c("span", { staticClass: "header-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getCityTextById")(item.locationId)
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "header-text" }, [
                              _c("i", [
                                _vm._v(
                                  _vm._s(
                                    item.minSalary == 0 && item.maxSalary == 0
                                      ? "面议"
                                      : item.minSalary +
                                          "K-" +
                                          item.maxSalary +
                                          "K"
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.resumeInfo.workExperiences?.length,
                    expression: "resumeInfo.workExperiences?.length",
                  },
                ],
                staticClass: "resume-module work-experience",
              },
              [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "module-main" },
                  _vm._l(
                    _vm.resumeInfo.workExperiences,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "workExperiences" + index,
                          staticClass: "module-item",
                        },
                        [
                          _c("div", { staticClass: "item-header" }, [
                            _c("p", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        item.startDate !=
                                          "0001-01-01T00:00:00" ||
                                        item.endDate != "0001-01-01T00:00:00",
                                      expression:
                                        "item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'",
                                    },
                                  ],
                                },
                                [
                                  _c("i", { staticClass: "header-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDateWithDotMonth")(
                                          item.startDate
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("i", { staticClass: "header-text" }, [
                                    _vm._v("-"),
                                  ]),
                                  _c("i", { staticClass: "header-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDateWithDotMonth")(
                                          item.endDate
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c("span", {
                                staticClass: "header-text",
                                domProps: { innerHTML: _vm._s(item.company) },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        item.startDate !=
                                          "0001-01-01T00:00:00" ||
                                        item.endDate != "0001-01-01T00:00:00",
                                      expression:
                                        "item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'",
                                    },
                                  ],
                                  staticClass: "header-text work-time",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("getMonthDiff")(
                                        item.startDate + "|" + item.endDate
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", [
                              _c("span", {
                                staticClass: "header-text",
                                domProps: { innerHTML: _vm._s(item.title) },
                              }),
                              _c("span", { staticClass: "header-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("getCityTextById")(item.location)
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.description,
                                  expression: "item.description",
                                },
                              ],
                              staticClass: "item-para item-flex",
                            },
                            [
                              _c("span", { staticClass: "para-description" }, [
                                _vm._v("工作描述："),
                              ]),
                              _c("span", {
                                staticClass: "itme-white-space",
                                domProps: {
                                  innerHTML: _vm._s(item.description),
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.resumeInfo.projectExperiences?.length,
                    expression: "resumeInfo.projectExperiences?.length",
                  },
                ],
                staticClass: "resume-module project-experience",
              },
              [
                _vm._m(2),
                _c(
                  "div",
                  { staticClass: "module-main" },
                  _vm._l(
                    _vm.resumeInfo.projectExperiences,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "projectExperiences" + index,
                          staticClass: "module-item",
                        },
                        [
                          _c("div", { staticClass: "item-header" }, [
                            _c("p", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        item.startDate !=
                                          "0001-01-01T00:00:00" ||
                                        item.endDate != "0001-01-01T00:00:00",
                                      expression:
                                        "item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'",
                                    },
                                  ],
                                },
                                [
                                  _c("i", { staticClass: "header-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDateWithDotMonth")(
                                          item.startDate
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("i", { staticClass: "header-text" }, [
                                    _vm._v("-"),
                                  ]),
                                  _c("i", { staticClass: "header-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDateWithDotMonth")(
                                          item.endDate
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c("span", {
                                staticClass: "header-text",
                                domProps: {
                                  innerHTML: _vm._s(item.projectName),
                                },
                              }),
                            ]),
                            item.projectRole
                              ? _c("p", [
                                  _c("span", { staticClass: "header-text" }, [
                                    _vm._v(
                                      "项目角色：" + _vm._s(item.projectRole)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.description,
                                  expression: "item.description",
                                },
                              ],
                              staticClass: "item-para item-flex",
                            },
                            [
                              _c("span", { staticClass: "para-description" }, [
                                _vm._v("项目描述："),
                              ]),
                              _c("span", {
                                staticClass: "itme-white-space",
                                domProps: {
                                  innerHTML: _vm._s(item.description),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    item.responsibility &&
                                    item.responsibility?.length > 0,
                                  expression:
                                    "item.responsibility && item.responsibility?.length > 0",
                                },
                              ],
                              staticClass: "item-para item-flex",
                            },
                            [
                              _c("span", { staticClass: "para-description" }, [
                                _vm._v("项目职责："),
                              ]),
                              _c("span", {
                                staticClass: "itme-white-space",
                                domProps: {
                                  innerHTML: _vm._s(item.responsibility),
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.resumeInfo.educationExperiences?.length,
                    expression: "resumeInfo.educationExperiences?.length",
                  },
                ],
                staticClass: "resume-module education-experience",
              },
              [
                _vm._m(3),
                _c(
                  "div",
                  { staticClass: "module-main" },
                  _vm._l(
                    _vm.resumeInfo.educationExperiences,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "educationExperiences" + index,
                          staticClass: "module-item",
                        },
                        [
                          _c("div", { staticClass: "item-header" }, [
                            _c("p", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        item.startDate !=
                                          "0001-01-01T00:00:00" ||
                                        item.endDate != "0001-01-01T00:00:00",
                                      expression:
                                        "item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'",
                                    },
                                  ],
                                },
                                [
                                  _c("i", { staticClass: "header-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDateWithDotMonth")(
                                          item.startDate
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("i", { staticClass: "header-text" }, [
                                    _vm._v("-"),
                                  ]),
                                  _c("i", { staticClass: "header-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDateWithDotMonth")(
                                          item.endDate
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c("span", {
                                staticClass: "header-text",
                                domProps: { innerHTML: _vm._s(item.school) },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        item.startDate !=
                                          "0001-01-01T00:00:00" ||
                                        item.endDate != "0001-01-01T00:00:00",
                                      expression:
                                        "item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'",
                                    },
                                  ],
                                  staticClass: "header-text work-time",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("getMonthDiff")(
                                        item.startDate + "|" + item.endDate
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", [
                              _c("span", {
                                staticClass: "header-text",
                                domProps: { innerHTML: _vm._s(item.major) },
                              }),
                              _c("span", { staticClass: "header-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("getDegreeTextById")(item.degree)
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.resumeInfo.selfEvaluation,
                    expression: "resumeInfo.selfEvaluation",
                  },
                ],
                staticClass: "resume-module self-evaluation",
              },
              [
                _vm._m(4),
                _c("div", { staticClass: "module-main" }, [
                  _c("div", { staticClass: "module-item" }, [
                    _c("div", {
                      staticClass: "item-para itme-white-space",
                      domProps: {
                        innerHTML: _vm._s(_vm.resumeInfo.selfEvaluation),
                      },
                    }),
                  ]),
                ]),
              ]
            ),
          ])
        : _c("div", { staticClass: "text-empty" }, [_vm._v("暂无数据")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "module-bar" }, [
      _c("div", { staticClass: "bar-title" }, [
        _c("i", { staticClass: "icon-font icon-zhiwei1" }),
        _c("span", [_vm._v("期望工作")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "module-bar" }, [
      _c("div", { staticClass: "bar-title" }, [
        _c("i", { staticClass: "icon-font icon-briefcase" }),
        _c("span", [_vm._v("工作经历")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "module-bar" }, [
      _c("div", { staticClass: "bar-title" }, [
        _c("i", { staticClass: "icon-font icon-tuceng" }),
        _c("span", [_vm._v("项目经历")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "module-bar" }, [
      _c("div", { staticClass: "bar-title" }, [
        _c("i", { staticClass: "icon-font icon-boshimao" }),
        _c("span", [_vm._v("教育经历")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "module-bar" }, [
      _c("div", { staticClass: "bar-title" }, [
        _c("i", { staticClass: "icon-font icon-flower" }),
        _c("span", [_vm._v("自我评价")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }