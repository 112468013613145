var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "opportunity-resume-detail",
    },
    [
      _c("div", { staticClass: "opportunity-resume-detail-header" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "avatar" }, [
            _vm.resumeInfo.avatarURL
              ? _c("img", { attrs: { src: _vm.resumeInfo.avatarURL } })
              : _c("img", {
                  attrs: {
                    src: require("@src/assets/images/default-user.png"),
                  },
                }),
          ]),
          _c("div", { staticClass: "base-info" }, [
            _c("p", { staticClass: "headline-base" }, [
              _c(
                "span",
                {
                  staticClass: "headline-name",
                  attrs: { title: _vm.resumeInfo.realName },
                },
                [_vm._v(_vm._s(_vm.resumeInfo.realName || "未知"))]
              ),
              _c("span", [
                _vm._v(_vm._s(_vm._f("genderFilter")(_vm.resumeInfo.gender))),
              ]),
              _c("i", { staticClass: "headline-border" }, [_vm._v("|")]),
              _c("span", [
                _vm._v(_vm._s(_vm.birthdayToAge(_vm.resumeInfo.birthday))),
              ]),
            ]),
            _c("div", { staticClass: "headline-info" }, [
              _c("div", { staticClass: "item" }, [
                _c("p", { staticClass: "school" }, [
                  _c("i", { staticClass: "headline-title" }, [
                    _vm._v("学校："),
                  ]),
                  _vm._v(
                    _vm._s(_vm.resumeInfo.school || "未知") +
                      "\n                        "
                  ),
                ]),
                _c("p", { staticClass: "degree" }, [
                  _c("i", { staticClass: "headline-title" }, [
                    _vm._v("学历："),
                  ]),
                  _vm._v(
                    _vm._s(_vm._f("degreeFilter")(_vm.resumeInfo.degree)) +
                      "\n                        "
                  ),
                ]),
                _c("p", { staticClass: "company" }, [
                  _c("i", { staticClass: "headline-title" }, [
                    _vm._v("公司："),
                  ]),
                  _vm._v(
                    _vm._s(_vm.resumeInfo.company || "未知") +
                      "\n                        "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("p", { staticClass: "mobile" }, [
                  _c("i", { staticClass: "headline-title" }, [
                    _vm._v("手机："),
                  ]),
                  _vm._v(
                    _vm._s(_vm.resumeInfo.mobile || "-") +
                      "\n                        "
                  ),
                ]),
                _c("p", { staticClass: "email" }, [
                  _c("i", { staticClass: "headline-title" }, [
                    _vm._v("邮箱："),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.resumeInfo.email || "-"))]),
                ]),
                _c("p", { staticClass: "wechat" }, [
                  _c("i", { staticClass: "headline-title" }, [
                    _vm._v("微信："),
                  ]),
                  _vm._v(
                    _vm._s(_vm.resumeInfo.wechat || "-") +
                      "\n                        "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("opportunity-resume-detail-content", {
        ref: "publicResumeContent",
        attrs: { resumeInfo: _vm.resumeInfo },
      }),
      _c("div", { staticClass: "lbd-sign" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }