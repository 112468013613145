<template>
    <div class="opportunity-resume-detail-content">
        <div class="standard-resume">
            <div class="resume-wrapper" 
                v-if="resumeInfo.intentions?.length || resumeInfo.workExperiences?.length || resumeInfo.projectExperiences?.length || resumeInfo.educationExperiences?.length || resumeInfo.selfEvaluation"
            >
                <!-- 期望工作 -->
                <div class="resume-module expect-intentions" v-show="resumeInfo.intentions?.length">
                    <div class="module-bar">
                        <div class="bar-title">
                            <i class="icon-font icon-zhiwei1"></i>
                            <span>期望工作</span>
                        </div>
                    </div>
                    <div class="module-main">
                        <div class="module-item" v-for="(item, index) in resumeInfo.intentions" :key="'intentions'+index">
                            <div class="item-header">
                                <p>
                                    <span class="header-text" v-html="item.title"></span>
                                    <span class="header-text">{{ item.locationId | getCityTextById }}</span>
                                    <span class="header-text">
                                    <!-- <i>{{ item.minSalary | tpl('%pk') }}</i> -
                                    <i>{{ item.maxSalary | tpl('%pk') }}</i> -->
                                    <i>{{ item.minSalary==0 && item.maxSalary==0 ? '面议' : item.minSalary + 'K-' + item.maxSalary + 'K'}}</i>
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 工作经历 -->
                <div class="resume-module work-experience" v-show="resumeInfo.workExperiences?.length">
                    <div class="module-bar">
                        <div class="bar-title">
                            <i class="icon-font icon-briefcase"></i>
                            <span>工作经历</span>
                        </div>
                    </div>
                    <div class="module-main">
                        <div class="module-item" v-for="(item, index) in resumeInfo.workExperiences" :key="'workExperiences'+index">
                            <div class="item-header">
                                <p>
                                    <span v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">
                                        <i class="header-text">{{ item.startDate | formatDateWithDotMonth }}</i>
                                        <i class="header-text">-</i>
                                        <i class="header-text">{{ item.endDate | formatDateWithDotMonth }}</i>
                                    </span>
                                    <span class="header-text" v-html="item.company"></span>
                                    <span class="header-text work-time" v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">{{ item.startDate + '|' + item.endDate | getMonthDiff }}</span>
                                </p>
                                <p>
                                    <span class="header-text" v-html="item.title"></span>
                                    <span class="header-text">{{ item.location | getCityTextById }}</span>
                                </p>
                            </div>
                            <div class="item-para item-flex" v-show="item.description">
                                <span class="para-description">工作描述：</span>
                                <span class="itme-white-space" v-html="item.description"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 项目经历 -->
                <div class="resume-module project-experience" v-show="resumeInfo.projectExperiences?.length">
                    <div class="module-bar">
                        <div class="bar-title">
                            <i class="icon-font icon-tuceng"></i>
                            <span>项目经历</span>
                        </div>
                    </div>
                    <div class="module-main">
                        <div class="module-item" v-for="(item, index) in resumeInfo.projectExperiences" :key="'projectExperiences'+index">
                            <div class="item-header">
                                <p>
                                    <span v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">
                                        <i class="header-text">{{ item.startDate | formatDateWithDotMonth }}</i>
                                        <i class="header-text">-</i>
                                        <i class="header-text">{{ item.endDate | formatDateWithDotMonth }}</i>
                                    </span>
                                    <span class="header-text" v-html="item.projectName"></span>
                                </p>
                                <p v-if="item.projectRole">
                                    <span class="header-text">项目角色：{{item.projectRole}}</span>
                                </p>
                            </div>
                            <div class="item-para item-flex" v-show="item.description" >
                                <span class="para-description">项目描述：</span>
                                <span class="itme-white-space" v-html="item.description"></span>
                            </div>
                            <div class="item-para item-flex" v-show="item.responsibility && item.responsibility?.length > 0" >
                                <span class="para-description">项目职责：</span>
                                <span class="itme-white-space" v-html="item.responsibility"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 教育经历 -->
                <div class="resume-module education-experience" v-show="resumeInfo.educationExperiences?.length">
                    <div class="module-bar">
                        <div class="bar-title">
                            <i class="icon-font icon-boshimao"></i>
                            <span>教育经历</span>
                        </div>
                    </div>
                    <div class="module-main">
                        <div class="module-item" v-for="(item, index) in resumeInfo.educationExperiences" :key="'educationExperiences'+index">
                            <div class="item-header">
                                <p>
                                    <span v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'">
                                        <i class="header-text">{{ item.startDate | formatDateWithDotMonth }}</i>
                                        <i class="header-text">-</i>
                                        <i class="header-text">{{ item.endDate | formatDateWithDotMonth }}</i>
                                    </span>
                                    <span class="header-text" v-html="item.school"></span>
                                    <span v-show="item.startDate!='0001-01-01T00:00:00' || item.endDate!='0001-01-01T00:00:00'" class="header-text work-time">{{ item.startDate + '|' + item.endDate | getMonthDiff }}</span>
                                </p>
                                <p>
                                    <span class="header-text" v-html="item.major"></span>
                                    <span class="header-text">{{ item.degree | getDegreeTextById }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 自我评价 -->
                <div class="resume-module self-evaluation" v-show="resumeInfo.selfEvaluation">
                    <div class="module-bar">
                        <div class="bar-title">
                            <i class="icon-font icon-flower"></i>
                            <span>自我评价</span>
                        </div>
                    </div>
                    <div class="module-main">
                        <div class="module-item">
                            <div class="item-para itme-white-space" v-html="resumeInfo.selfEvaluation"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-empty" v-else>暂无数据</div>
        </div>
    </div>
</template>

<script>
import { getCityTextById } from '#/js/util/provinceCity.js';
import degreeInfoData from '#/js/config/degreeKeyValue.json';

export default {
    props: {
        resumeInfo: Object
    },
    data() {
        return {
            intentions: [],
            workExperiences: [],
            projectExperiences: [],
            educationExperiences: [],
            selfEvaluation: '',
        };
    },

    filters: {
        getCityTextById(id) {
            return getCityTextById(id);
        },
        getDegreeTextById (id) {
            return degreeInfoData[id] || '';
        },
    },

    mounted() {

    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.opportunity-resume-detail-content {
    width: 1200px;
    margin: 0 auto;
    // min-height: calc(100vh - 324px);
    min-height: calc(100vh - 280px);
    background-color: #fff;
    border-radius: 8px;

    .standard-resume{
        padding: 20px 30px;
        .resume-module{
            .module-bar{
                .bar-title{
                    display: inline-block;
                    margin-bottom: 10px;
                    color: #444;
                    font-weight: bold;
                    .icon-font{
                        color: #666;
                        margin-right: 5px;
                    }
                }

                &::after{
                    content: "";   
                    display: inline-block;   
                    // width: 680px;
                    width: calc(100% - 100px);
                    height: 1px;  
                    background-color: rgba(56,188,157,0.2);
                    vertical-align: middle;
                    margin-left: 10px;
                }
            }

            .module-main{
                position: relative;
                padding-left: 25px;
                .module-item{
                    padding-bottom: 16px;
                }
                .candidate-resume-work-form,
                .candidate-resume-education-form,
                .candidate-resume-project-form,
                .candidate-resume-evaluation-form,
                .candidate-resume-intention-form{
                    float: none;
                    margin-left: -20px;
                }
            }

            .item-header{
                width: 690px;
                p{
                    line-height: 30px;
                    color: #444;
                    margin-bottom: 0;
                    position: relative;
                    .header-text{
                        display: inline-block;
                        max-width: 300px;
                        vertical-align: middle;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        &.work-time {
                            margin-left: 10px;
                            font-size: 12px;
                            color: #666;
                        }
                    }

                    span + span{
                        margin-left: 20px;
                    }

                    i{
                        font-style: normal;
                    }

                    &:first-child{
                        &:before{
                            content: "";
                            background-color: #CCEEE6;
                            position: absolute;
                            left: -24px;
                            top: 8px;
                            width: 14px;
                            height: 14px;
                            border-radius: 7px;
                            display: inline-block;
                        }
                        &:after{
                            content: "";
                            background-color: $primary;
                            position: absolute;
                            left: -20px;
                            top: 12px;
                            width: 6px;
                            height: 6px;
                            border-radius: 3px;
                            display: inline-block;
                            z-index: 1;
                        }
                    }
                }
            }

            .item-para{
                padding: 5px 0;
                word-wrap: break-word;
                font-size: 14px;
                line-height: 20px;
                &.item-flex{
                    display: flex;
                }
                .para-description{
                    white-space: nowrap;
                    display: inline-block;
                    color: #444;
                }
            }
            .itme-white-space{
                white-space: pre-line;
                // width: 660px;
                width: 90%;
            }

        }
        .candidate-resume-work-form,
        .candidate-resume-education-form,
        .candidate-resume-project-form,
        .candidate-resume-evaluation-form,
        .candidate-resume-intention-form{
            display: block;
        }

        input.hidden{
            display: none;
        }
    }

    .text-empty{
        text-align: center;
        padding: 20px 0;
    }
}
</style>